import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

import ReactFlow, { Controls, ReactFlowProvider } from 'react-flow-renderer';

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";

import axios from "axios";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import EditNodeModal from "../../../components/Common/EditNodeModal";
import CustomDeleteNode from "../../../components/Common/CustomDeleteNode";

import { getClient, editClient, getUsers, apiError, clientSetLoaded } from "../../../store/actions";

import { buildWizard } from "../../../helpers/wizard_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";

let id = 1;

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      user: '',
      flow: '',
      wizard: '',
      css: '',
      clientImages: [],
      nodes:  [
        {
          id: id.toString(),
          type: 'input',
          data: { label: 'Inicio', title: 'Inicio' },
          position: { x: 0, y: 0 },
          selectable: false
        },
      ],
      edges: [],
      node: null,
      users: [],
      showEditNodeModal: false
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.reactFlowInstance = React.createRef(null);
    this.reactFlowWrapper = React.createRef(null);
    this.connectingNodeId = React.createRef(null);
  }

  componentDidMount() {
    this.props.getClient({ id: this.props.match.params.id, callback: (result) => this.onClientSuccess(result) });

    this.props.getUsers({ callback: (result) => this.onUsersSuccess(result) });
  }

  onClientSuccess(success) {
    if (success) {
      this.setState({
        id: this.props.client.id,
        name: this.props.client.name,
        user: this.props.client.user,
        css: this.props.client.css,
        flow: this.props.client.flow || '',
        wizard: JSON.stringify(JSON.parse(this.props.client.wizard), null, 2)
      });

      this.getImages();
    }
  }

  onUsersSuccess(success) {
    if (success) {
      this.setState({
        users: this.props.users
      });
    }
  }

  setFlow() {
    const flow = this.state.flow ? JSON.parse(this.state.flow) : false;

    if (flow) {
      const { x = 0, y = 0, zoom = 1 } = flow.viewport;
      
      const nodes = flow.nodes.map((n) => {
        n.data.onDelete = () => { this.handleDeleteNode(n.id); };
        n.type = 'customDeleteNode';

        return n;
      });

      this.reactFlowInstance.setNodes(nodes || []);
      this.reactFlowInstance.setEdges(flow.edges || []);
      this.reactFlowInstance.setViewport({ x, y, zoom });

      id = parseInt(flow.nodes[flow.nodes.length - 1].id);
    } else {
      this.reactFlowInstance.setNodes(this.state.nodes);
      this.reactFlowInstance.setEdges([]);
    }
  }

  handleValidSubmit(event, values) {
    let flow = this.reactFlowInstance.toObject();

    values.flow = JSON.stringify(flow);
    values.wizard = buildWizard(this.reactFlowInstance.toObject());
    values.css = this.state.css;

    this.props.editClient(values, (result) => this.onClientEdit(result, values));
  }

  onClientEdit(result, values) {
    if (result) {
      this.setState({
        id: values.id,
        name: values.name,
        user: values.user,
        flow: values.flow,
        wizard: JSON.stringify(JSON.parse(values.wizard), null, 2),
        css: values.css
      });
    }
  }

  getImages() {
    axios.get(process.env.REACT_APP_API_URL + "/client/" + this.props.match.params.id + "/images").then(res => {
      const fireBaseBackend = getFirebaseBackend();
      let beImages = res.data.data;

      fireBaseBackend.getAllImages(this.props.match.params.id).then((response) => {
        let files = [];

        response.items.forEach((imageRef, index, array) => {
          files.push(imageRef.name);
        });

        beImages = beImages.concat(files);

        this.setState({ clientImages: beImages });
      });
    }).catch(err => {
    });
  }

  handleConfirmEditNode(nid, values) {
    let nodes = this.reactFlowInstance.getNodes();

    nodes = nodes.map((n) => {
      if (n.id == nid) {
        n.data = {
          ...values,
          label: values.title,
        };
      }

      return n;
    });

    this.reactFlowInstance.setNodes(nodes);
    
    this.setState({ showEditNodeModal: false });
  }

  handleCancelEditNode() {
    this.setState({ showEditNodeModal: false });
  }

  handleConnect(connection) {
    let nodes = this.reactFlowInstance.getNodes();
    let edges = this.reactFlowInstance.getEdges();

    nodes = nodes.map((n) => {
      if (n.id == connection.target) {
        n.parent = connection.source;

        edges = edges.map((e) => {
          if (e.id == n.id) {
            e.source = connection.source;
          }

          return e;
        });
      }

      return n;
    });

    this.reactFlowInstance.setNodes(nodes);
    this.reactFlowInstance.setEdges(edges);
  }

  handleDeleteNode(id) {
    let nodes = this.reactFlowInstance.getNodes();
    let edges = this.reactFlowInstance.getEdges();
    nodes = nodes.filter((n) => n.id != id );
    edges = edges.filter((e) => e.id != id );

    this.reactFlowInstance.setNodes(nodes);
    this.reactFlowInstance.setEdges(edges);
  }

  handleDuplicateNode(node) {
    let id = this.reactFlowInstance.getNodes().length;
    id += 1;
                    
    const newNode = {
      id: id.toString(),
      type: 'customDeleteNode',
      position: this.reactFlowInstance.project({ x: 50, y: 50 }),
      data: { ...node.data, id }
    };

    this.reactFlowInstance.addNodes(newNode);
    this.reactFlowInstance.addEdges({ id: id.toString(), source: null, target: id.toString() });

    this.setState({ showEditNodeModal: false });
  }

  render() {
    const nodeTypes = { customDeleteNode: CustomDeleteNode };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Clientes" breadcrumbItem="Detalles" />
            { this.props.loading ? (
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            ) : (
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                this.handleValidSubmit(e, v)
              }}
            >
              {this.props.updated ? (
                <Alert color="success">
                  Datos guardados correctamente.
                </Alert>
              ) : null}
              {this.props.error ? (
                <Alert color="danger">
                  {this.props.error}
                </Alert>
              ) : null}
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">General</CardTitle>

                      <AvField
                        name="id"
                        value={this.state.id}
                        label="ID"
                        type="text"
                        placeholder="ID"
                        errorMessage=" "
                        className="mb-3"
                        readOnly
                        disabled
                      />

                      <Row>
                        <Col>
                          <AvField
                            name="name"
                            value={this.state.name}
                            label="Nombre"
                            type="text"
                            placeholder="Nombre"
                            errorMessage=" "
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <AvField
                            name="user"
                            value={this.state.user}
                            label="Usuario"
                            type="select"
                            errorMessage=" "
                          >
                            <option value="">Seleccione</option>
                            {this.state.users.map((u) => {
                              return <option value={u.uid} key={u.uid}>{u.email}</option>
                            })}
                          </AvField>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">JSON</CardTitle>

                      <AceEditor
                        mode="json"
                        onChange={(json) => { this.setState({ wizard: json }) }}
                        name="json"
                        width="100%"
                        value={this.state.wizard}
                        showPrintMargin={false}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          showLineNumbers: true,
                          tabSize: 2,
                          readOnly: true
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card style={{ height: '100vh'}}>
                    <CardBody>
                      <CardTitle className="h4">Workflow</CardTitle>
                      <ReactFlowProvider>
                        <div className="react-flow-wrapper" ref={this.reactFlowWrapper}>
                          <ReactFlow
                            defaultNodes={this.state.nodes}
                            defaultEdges={this.state.edges}
                            defaultEdgeOptions={{ animated: true }}
                            nodeTypes={nodeTypes}
                            fitView
                            fitViewOptions={{ padding: 8 }}
                            zoomOnScroll={false}
                            onInit={(reactFlowInstance) => {
                              this.reactFlowInstance = reactFlowInstance;
                              this.setFlow();
                            }}
                            onNodeDoubleClick={(event, node) => {
                              node.data.clientId = this.props.match.params.id;
                              this.setState({ node, showEditNodeModal: true });
                            }}
                            onConnect={(connection) => {
                              this.handleConnect(connection);
                            }}
                            onConnectStart={(_, { nodeId }) => {
                              this.connectingNodeId.current = nodeId;
                            }}
                            onConnectStop={(event) => {
                              const targetIsPane = event.target.classList.contains('react-flow__pane');

                              if (targetIsPane) {
                                id += 1;

                                // we need to remove the wrapper bounds, in order to get the correct position
                                const { top, left } = this.reactFlowWrapper.current.getBoundingClientRect();

                                const newNode = {
                                  id: id.toString(),
                                  type: 'customDeleteNode',
                                  // we are removing the half of the node width (75) to center the new node
                                  position: this.reactFlowInstance.project({ x: event.clientX - left - 75, y: event.clientY - top }),
                                  data: { id: id.toString(), label: 'Opción', title: 'Opción', onDelete: (id) => { this.handleDeleteNode(id); } },
                                };

                                this.reactFlowInstance.addNodes(newNode);
                                this.reactFlowInstance.addEdges({ id: id.toString(), source: this.connectingNodeId.current, target: id.toString() });
                              }
                            }}
                          >
                            <Controls />
                          </ReactFlow>
                        </div>
                      </ReactFlowProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">CSS</CardTitle>

                      {!!this.props.client ? (
                        <AceEditor
                          mode="css"
                          onChange={(css) => { this.setState({ css: css }) }}
                          name="css"
                          width="100%"
                          value={this.state.css}
                          showPrintMargin={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                          }}
                        />
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Imágenes</CardTitle>
                      <a href={"/admin/clients/" + this.props.match.params.id + "/images"} className="btn btn-info w-md">
                        Agregar
                      </a>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mb-3">
                <button type="submit" className="btn btn-primary w-md mx-3">
                  Guardar
                </button>

                <a href={"/" + this.props.match.params.id} target="_blank" className="btn btn-success w-md">
                  Ver
                </a>
              </div>
            </AvForm>
            )}
          </Container>
          <EditNodeModal 
              show={this.state.showEditNodeModal} 
              node={this.state.node}
              clientImages={this.state.clientImages}
              onConfirmClick={this.handleConfirmEditNode.bind(this)} 
              onCloseClick={this.handleCancelEditNode.bind(this)}
              onDuplicateClick={this.handleDuplicateNode.bind(this)}></EditNodeModal>
        </div>
      </React.Fragment>
    )
  }
}

Configuration.propTypes = {
  client: PropTypes.object,
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { loading, client, updated, error } = state.Clients;
  const { users } = state.Users;

  return {
    loading, client, updated, users, error
  };
};

export default connect(mapStateToProps, {
  getClient,
  editClient,
  getUsers,
  apiError,
  clientSetLoaded
})(withRouter(Configuration));