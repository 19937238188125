import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Styled from "styled-components";
import Redirect from "../components/Redirect";
import Text from "../components/Text";
import Popup from "../components/Popup";
import Conditional from "../components/Conditional";
import Iframe from "../components/Iframe";
import Image from "../components/Common/Image";

import { getWizard } from "../store/actions";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      option: null,
      steps: [],
      footer: [],
      styles: this.props.css ? this.props.css : ''
    };
  }

  componentDidMount() {
    if (this.props.wizard) {
      this.onWizardSuccess(true);
    }
  }

  onWizardSuccess(success) {
    if (success) {
      const options = this.props.wizard.options;
      options.sort((a, b) => { return a.order - b.order });

      this.setState({
        steps: options,
        footer: this.props.wizard.footer || [],
        styles: this.props.css
      });
    }
  }

  renderComponent(step) {
    switch (step.type) {
      case 'redirect':
        return <Redirect step={step} wizard={this.props.wizard}></Redirect>;
      case 'text':
        return <Text step={step} wizard={this.props.wizard}></Text>;
      case 'html':
        return <Popup step={step} wizard={this.props.wizard}></Popup>;
      case 'conditional':
        return <Conditional step={step} wizard={this.props.wizard}></Conditional>;
      case 'iframe':
        return <Iframe step={step} wizard={this.props.wizard}></Iframe>;
    }
  }

  setOption(option) {
    if (option.type != 'conditional') {
      let steps = this.state.steps.map((step) => {
        if (step.options) {
          step.options.sort((a, b) => { return a.order - b.order });
        }
        
        if (option.id == step.id) {
          step.selectedClass = 'selected';
        } else {
          step.selectedClass = '';
        }
  
        return step;
      });
  
      this.setState({ steps, option }, () => this.nextStep());
    }
  }

  nextStep() {
    let steps = this.state.steps.map((step) => {
      step.selectedClass = '';

      return step;
    });

    this.setState({ steps });

    this.props.history.push('/' + this.props.id + '/' + this.state.option.id + this.props.location.search);
  }

  Wrapper = () => Styled.div`
    ${this.state.styles}
  `;
  render() {
    const Wrapper = this.Wrapper();

    return (
      this.props.wizard ? (
      <Wrapper>
        <Container className="wizard-container">
          <Row className="justify-content-center mt-3 mb-3">
            <Col>
              <div className="text-center cursor-pointer">
                <Image image={this.props.wizard.logo} id={this.props.match.params.id} onClick={() => { this.props.history.push('/' + this.props.id) }} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mb-3">
            <Col md={6} xl={3}>
              <h1>{this.props.wizard.title}</h1>
              { this.props.wizard.subtitle ?
                <h5>{this.props.wizard.subtitle}</h5>
              : undefined }
            </Col>
          </Row>
          <Row className="justify-content-center">
            {this.state.steps.map((step, key) => {
              return (
                <Col md={step.type == 'conditional' ? 6 : step.type == 'iframe' ? 12 : 3} xs={12} key={step.id} className="mb-3">
                  {step.options && step.options.length ? (
                    this.props.wizard.mode == 'icon' ?
                    <Card className={`icon-card h-100 cursor-pointer ${step.selectedClass}`} style={step.styles} onClick={() => { this.setOption(step) }}>
                      <CardBody className="d-flex align-items-center">
                        <Row className="align-items-center">
                          <Col>
                            <Image image={step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
                          </Col>
                          {step.title ?
                          <Col>
                            <CardTitle className="h4 mt-0">{step.title}</CardTitle>
                          </Col>
                          : undefined }
                        </Row>
                      </CardBody>
                    </Card>
                    :
                    <>
                      { !['conditional', 'iframe'].includes(step.type) ?
                      <Card className={`image-card h-100 cursor-pointer ${step.selectedClass}`} style={step.styles} onClick={() => { this.setOption(step) }}>
                        <Image image={step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
                        <CardBody>
                          <CardTitle className="h4 mt-0">{step.title}</CardTitle>
                          <CardText dangerouslySetInnerHTML={{ __html: step.content ? step.content.replace(/(<? *script)/gi, 'illegalscript') : '' }}>
                          </CardText>
                        </CardBody>
                      </Card>
                      : this.renderComponent(step) }
                    </>
                  ) : this.renderComponent(step)}
                </Col>
              );
            })}
            {/* <Row className="justify-content-center text-center mt-3 actions">
              <Col md={3} xs={12} className="p-0">
                <button disabled={!this.state.option} onClick={() => this.nextStep() } className="btn">Continuar</button>
              </Col>
            </Row> */}
          </Row>
          {this.state.footer.length ?
          <footer className="main-footer mx-3">
            <ul className="list-unstyled list-inline text-center">
            {this.state.footer.map((link, key) => {
              return (
                <li className="list-inline-item" key={key}>
                  <a href={link.url} target="_blank" className="mx-2">{link.text}</a>
                </li>
              );
            })}
            </ul>
          </footer>
          : undefined}
        </Container>
      </Wrapper>
      ) : null
    );
  }
}

Home.propTypes = {
  id: PropTypes.string,
  wizard: PropTypes.object,
  css: PropTypes.string,
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { loading, id, wizard, css, error } = state.Wizard;
  return {
    loading, id, wizard, css, error
  };
};

export default connect(mapStateToProps, {
  getWizard
})(withRouter(Home));
