import { uniq } from "lodash";

function getStepOptions(nodes, nid = 0) {
  return nodes.reduce((r, e) => {
    if (e.parents.includes(nid)) {
      const o = { ...e.data };
      const c = getStepOptions(nodes, e.id);

      if (c.length) o.options = c;

      delete o.label;

      r.push(o);
    }

    return r;
  }, []);
}

export function buildWizard(flow) {
  const nodes = flow.nodes;
  const edges = flow.edges;
  const start = nodes[0];

  let wizard = {
    title: start.data.title,
    subtitle: start.data.subtitle,
    color: start.data.color,
    logo: start.data.logo,
    background: start.data.background,
    mode: start.data.mode,
    footer: start.data.footer ? JSON.parse(start.data.footer) : '',
    options: []
  };

  nodes.forEach(n => {
    const edgs = edges.filter((e) => { return e.target == n.id });
    const parents = edgs.length ? edgs.map((e) => e.source) : null;

    n.parents = uniq(parents);
  });

  let arr = getStepOptions(nodes, start.id);
  wizard.options = arr;

  return JSON.stringify(wizard);
}
