import React from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Image from "./Common/Image";

class Redirect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: props.step
    };
  }

  doRedirect() {
    try {
      const query = new URLSearchParams(this.props.location.search);
      let url = this.state.step && this.state.step.url ? this.state.step.url : null;

      query.forEach((value, param) => {
        if (url.indexOf('@' + param)) {
          url = url.replace('@' + param, value);
        }
      });

      if (url && this.state.step) {
        if (this.state.step.target) {
          window.open(url, this.state.step.target).focus();
        } else {
          window.open(url).focus();
        }
      }
    } catch (error) {
      window.location.reload();
    }
  }

  render() {
    return (
      this.props.wizard.mode == 'icon' ?
      <Card className="icon-card h-100 cursor-pointer" onClick={() => { this.doRedirect() }}>
        <CardBody className="d-flex align-items-center">
          <Row className="align-items-center">
            <Col>
              <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
            </Col>
            {this.state.step.title ?
            <Col>
              <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
            </Col>
            : undefined }
          </Row>
        </CardBody>
      </Card>
      :
      <Card className="h-100 cursor-pointer" onClick={() => { this.doRedirect() }}>
        <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
        <CardBody>
          <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
          <CardText dangerouslySetInnerHTML={{ __html: this.state.step.content.replace(/(<? *script)/gi, 'illegalscript') }}>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(Redirect);