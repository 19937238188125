import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Card, CardBody, CardTitle, Button, CardFooter } from "reactstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getClient, getImages, deleteImage } from "../../../store/actions";

import { getFirebaseBackend } from "helpers/firebase_helper";

class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
      key: new Date(),
      images: [],
      newImages: [],
      columns: [{
        dataField: 'name',
        text: 'Nombre'
      }, {
        dataField: 'actions',
        text: 'Acciones',
        isDummyField: true,
        formatter: (cellContent, row) => {
          return (
            <>
              <Button className="btn-danger mx-3" onClick={(e) => { if (window.confirm("Quiere eliminar ésta imagen? " + row.name)) { this.handleDelete(row);} }}>Eliminar</Button>
            </>
          );
        }
      }],
      duplicating: false,
      searchText: '',
      loading: false,
    };
  }

  componentDidMount() {
    this.props.getClient({ id: this.props.match.params.id, callback: (result) => this.onClientSuccess(result) });

    this.getImages();
  }

  onClientSuccess(success) {
    if (success) {
      this.setState({
        client: this.props.client.name,
      });
    }
  }

  onImagesSuccess(success) {
    if (success) {
      this.setState({
        images: this.props.images
      });
    }
  }

  getImages() {
    this.setState({ newImages: [], key: new Date(), loading: false });

    this.props.getImages({
      clientId: this.props.match.params.id,
      callback: (result) => this.onImagesSuccess(result)
    });
  }

  onFileChange(e) {
    this.setState({ newImages: e.target.files });
  }

  async addImages() {
    this.setState({ loading: true });

    if (this.state.newImages.length) {
      const fireBaseBackend = getFirebaseBackend();
      
      await fireBaseBackend.uploadImages(`clients/${this.props.match.params.id}/`, this.state.newImages);

      this.getImages();
    }
  }

  handleDelete(image) { 
    this.props.deleteImage(
      this.props.match.params.id,
      image,
      () => {
        this.setState({
          images: []
        }, () => {
          this.getImages();
        });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Card>
              <CardBody>
                <CardTitle className="h4">{this.state.client} - Imágenes</CardTitle>
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4">Subir imágenes</CardTitle>
                          <input key={this.state.key} type="file" name="images" onChange={this.onFileChange.bind(this)} multiple accept="image/*" />
                          <button type="button" className="btn btn-primary w-md" disabled={this.state.loading || !this.state.newImages.length} onClick={this.addImages.bind(this)}>
                            Subir
                          </button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="12">
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <BootstrapTable
                            keyField='name'
                            columns={this.state.columns}
                            data={this.state.images}
                            responsive
                            bordered={false}
                            striped={true}
                            classes={
                              "table align-middle table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            noDataIndication={() => (this.props.loading) ? <div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Cargando...</span></div> : <span>No existen imágenes.</span>}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-primary w-md" onClick={() => { this.props.history.goBack() }}>
                      Volver
                    </button>
                  </div>
                </CardFooter>
              </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Images.propTypes = {
  images: PropTypes.array,
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { client } = state.Clients;
  const { loading, images, error } = state.Images;
  return {
    client, loading, images, error
  };
};

export default connect(mapStateToProps, {
  getClient,
  getImages,
  deleteImage
})(withRouter(Images));