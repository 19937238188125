import React, { memo } from "react";
import { Handle, Position } from "react-flow-renderer";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <div>
        <span className="node-label" title={data.label}>{data.label}</span> <i className="bx bx-trash" onClick={() => { data.onDelete(data.id) }}></i>
        <span className="node-subtitle" title={data.subtitle}>{data.subtitle}</span>
      </div>
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </>
  );
});
