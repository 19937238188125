import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Forbidden extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col>
                Ud. no tiene acceso a esta página.
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Forbidden.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(withRouter(Forbidden));