import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import * as storage from "helpers/storage_helper";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      let authUser = storage.local.getItem("authUser");
      let u = authUser ? JSON.parse(authUser) : null;
      let step = rest.step;
      
      if (step && step.options) {
        step.options.sort((a, b) => { return a.order - b.order });
      }

      if (isAuthProtected && !authUser) {
        return (
          <Redirect
            to={{ pathname: "/admin/login", state: { from: props.location } }}
          />
        )
      } else if (isAuthProtected && (props.location.pathname.includes('add') || props.location.pathname.includes('users')) && 
      !props.location.pathname.includes('forbidden') && u && u.role == 'client') {
        return (
          <Redirect
            to={{ pathname: "/admin/forbidden" }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} step={step} />
        </Layout>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  step: PropTypes.object,
};

export default AppRoute;
