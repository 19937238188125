import { USERS_GET, USERS_GET_SUCCESS, USER_GET, USER_GET_SUCCESS, 
  USER_ADD, USER_DELETE, USER_DELETE_SUCCESS, USER_API_ERROR, USER_SET_LOADED } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  users: [],
  user: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USERS_GET:
      state = { ...state, users: [], loading: true, error: "" };
      break;
    case USERS_GET_SUCCESS:
      state = { ...state, users: action.payload, error: "", loading: false };
      break;
    case USER_GET:
      state = { ...state, loading: true, error: "" };
      break;
    case USER_GET_SUCCESS:
      state = { ...state, user: action.payload, error: "", loading: false };
      break;
    case USER_ADD:
      state = { ...state, loading: true, error: "" };
      break;
    case USER_DELETE:
      state = { ...state, loading: true, error: "" };
      break;
    case USER_DELETE_SUCCESS:
      state = { ...state, loading: false, error: "" };
      break;
    case USER_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case USER_SET_LOADED:
      state = { ...state, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default user;
