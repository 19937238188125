import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { addUser, apiError } from "../../../store/actions";

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      role: ''
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    this.props.addUser(values, (result, response) => this.onUserAdd(result, response));
  }

  onUserAdd(result, response) {
    if (result) {
      this.props.history.push("/admin/users");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Usuarios" breadcrumbItem="Agregar" />
            { this.props.loading ? (
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            ) : (
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                this.handleValidSubmit(e, v)
              }}
            >
              {this.props.error ? (
                <Alert color="danger">
                  {this.props.error}
                </Alert>
              ) : null}
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">General</CardTitle>

                      <Row>
                        <Col>
                          <AvField
                            name="email"
                            value={this.state.email}
                            label="Email"
                            type="text"
                            placeholder="Email"
                            errorMessage=" "
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <AvField
                            name="role"
                            value={this.state.role}
                            label="Rol"
                            type="select"
                            errorMessage=" "
                            required
                          >
                            <option value="">Seleccione</option>
                            <option value="admin">Administrador</option>
                            <option value="client">Cliente</option>
                          </AvField>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <AvField
                            name="password"
                            value={this.state.password}
                            label="Password"
                            type="password"
                            placeholder="Password"
                            errorMessage=" "
                            required
                            minLength={6}
                            helpMessage="Mínimo 6 caracteres"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="d-grid justify-content-end mb-3">
                <button type="submit" className="btn btn-primary w-md">
                  Guardar
                </button>
              </div>
            </AvForm>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Add.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { loading, error } = state.Users;
  return {
    loading, error
  };
};

export default connect(mapStateToProps, {
  addUser,
  apiError
})(withRouter(Add));