import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/admin/Authentication/Login";
import Logout from "../pages/admin/Authentication/Logout";

// Clients
import Clients from "../pages/admin/Clients/Clients";
import Add from "../pages/admin/Clients/Add";
import Configuration from "../pages/admin/Clients/Configuration";

// Images
import Images from "../pages/admin/Clients/Images";

// Users
import Users from "../pages/admin/Users/Users";
import AddUser from "../pages/admin/Users/Add";

import Forbidden from "../pages/admin/Forbidden";

const authProtectedRoutes = [
  { path: "/admin/clients", component: Clients },
  { path: "/admin/clients/add", component: Add },
  { path: "/admin/clients/:id", component: Configuration },

  { path: "/admin/clients/:id/images", component: Images },

  { path: "/admin/users", component: Users },
  { path: "/admin/users/add", component: AddUser },

  { path: "/admin/forbidden", component: Forbidden },

  // this route should be at the end of all other routes
  { path: "/admin", exact: true, component: () => <Redirect to="/admin/clients" /> },
];

const publicRoutes = [
  { path: "/admin/login", component: Login },
  { path: "/admin/logout", component: Logout },
]

export { authProtectedRoutes, publicRoutes };
