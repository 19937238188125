import { WIZARD_GET, WIZARD_GET_SUCCESS, WIZARD_API_ERROR } from "./actionTypes";

export const getWizard = payload => {
  return {
    type: WIZARD_GET,
    payload: payload,
  }
};

export const getWizardSuccess = payload => {
  return {
    type: WIZARD_GET_SUCCESS,
    payload: payload,
  }
};

export const apiError = error => {
  return {
    type: WIZARD_API_ERROR,
    payload: error,
  }
};