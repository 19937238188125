import { WIZARD_GET, WIZARD_GET_SUCCESS, WIZARD_API_ERROR } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  id: null,
  wizard: null,
  css: null
};

const wizard = (state = initialState, action) => {
  switch (action.type) {
    case WIZARD_GET:
      state = { ...state, loading: true, error: "" };
      break;
    case WIZARD_GET_SUCCESS:
      state = { ...state, loading: false, id: action.payload.id, wizard: JSON.parse(action.payload.wizard), css: action.payload.css };
      break;
    case WIZARD_API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default wizard;
