import { CLIENTS_GET, CLIENTS_GET_SUCCESS, CLIENT_GET, CLIENT_GET_SUCCESS, 
  CLIENT_ADD, CLIENT_UPDATE, CLIENT_UPDATE_SUCCESS, CLIENT_DELETE, CLIENT_DELETE_SUCCESS, 
  CLIENT_API_ERROR, CLIENT_SET_LOADED } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  updated: false,
  clients: [],
  client: null
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_GET:
      state = { ...state, clients: [], loading: true, error: "", updated: false };
      break;
    case CLIENTS_GET_SUCCESS:
      state = { ...state, clients: action.payload, error: "", loading: false, updated: false };
      break;
    case CLIENT_GET:
      state = { ...state, loading: true, error: "", updated: false };
      break;
    case CLIENT_GET_SUCCESS:
      state = { ...state, client: action.payload, error: "", loading: false, updated: false };
      break;
    case CLIENT_ADD:
      state = { ...state, loading: true, error: "" };
      break;
    case CLIENT_UPDATE:
      state = { ...state, loading: true, error: "" };
      break;
    case CLIENT_UPDATE_SUCCESS:
      state = {
        ...state,
        clients: state.clients.map(client =>
          client.id === action.payload.id
            ? { client, ...action.payload }
            : client
        ),
        updated: true,
        loading: false,
        error: ""
      };
      break;
    case CLIENT_DELETE:
      state = { ...state, loading: true, error: "" };
      break;
    case CLIENT_DELETE_SUCCESS:
      state = { ...state, loading: false, error: "" };
      break;
    case CLIENT_API_ERROR:
      state = { ...state, error: action.payload, loading: false, updated: false };
      break;
    case CLIENT_SET_LOADED:
      state = { ...state, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default client;
