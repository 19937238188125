import { Component } from "react";
import PropTypes from "prop-types";
import { CardImg } from "reactstrap";

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    if (this.props.image) {
      if (this.props.isCard) {
        return <CardImg top className="img-fluid" src={this.props.image} />
      } else {
        return <img className="img-fluid" src={this.props.image} />
      }
    } else {
      return <></>;
    }
  }
}

Image.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  isCard: PropTypes.bool
};

export default Image;
