import React from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Image from "./Common/Image";

class Text extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: props.step
    };
  }

  render() {
    return (
      this.props.wizard.mode == 'icon' ?
      <Card className="icon-card h-100">
        <CardBody className="d-flex align-items-center">
          <Row className="align-items-center">
            <Col>
              <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
            </Col>
            {this.state.step.title ?
            <Col>
              <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
            </Col>
            : undefined }
          </Row>
        </CardBody>
      </Card>
      :
      <Card className="h-100">
        <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
        <CardBody>
          <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
          <CardText dangerouslySetInnerHTML={{ __html: this.state.step.content.replace(/(<? *script)/gi, 'illegalscript') }}>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(Text);