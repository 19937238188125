import { IMAGES_GET, IMAGES_GET_SUCCESS, IMAGE_DELETE, IMAGE_DELETE_SUCCESS, 
  IMAGE_API_ERROR, IMAGE_SET_LOADED } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  updated: false,
  images: [],
};

const image = (state = initialState, action) => {
  switch (action.type) {
    case IMAGES_GET:
      state = { ...state, images: [], loading: true, error: "", updated: false };
      break;
    case IMAGES_GET_SUCCESS:
      state = { ...state, images: action.payload, error: "", loading: false, updated: false };
      break;
    case IMAGE_DELETE:
      state = { ...state, loading: true, error: "" };
      break;
    case IMAGE_DELETE_SUCCESS:
      state = { ...state, loading: false, error: "" };
      break;
    case IMAGE_API_ERROR:
      state = { ...state, error: action.payload, loading: false, updated: false };
      break;
    case IMAGE_SET_LOADED:
      state = { ...state, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default image;
