import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";

// Clients
import Clients from "./clients/reducer";

// Images
import Images from "./images/reducer";

// Users
import Users from "./users/reducer";

// Wizard
import Wizard from "./wizard/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Clients,
  Images,
  Users,
  Wizard
});

export default rootReducer;
