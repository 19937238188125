// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_TOPBAR_THEME,
} from "./actionTypes";

const INIT_STATE = {
  layoutType: "vertical",
  topbarTheme: "light",
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    default:
      return state;
  }
}

export default Layout;
