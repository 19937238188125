import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex align-items-center justify-content-between pb-2">
              <h4 className="mb-0 mr-5 font-size-18">{this.props.title}</h4>
              {this.props.breadcrumbItem ? (
              <div className="page-title-right">
                <Breadcrumb listClassName="m-0">
                  <BreadcrumbItem>
                    <Link to="#">{this.props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{this.props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
              ) : null }
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
};

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, {
})(withRouter((Breadcrumbs)));
