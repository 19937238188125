import React from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Image from "./Common/Image";

class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: props.step,
      show: false,
      hover: false
    };
  }

  render() {
    return (
      this.props.wizard.mode == 'icon' ?
      <Card className="icon-card h-100 cursor-pointer" onClick={() => this.setState({ show: true })}>
        <CardBody className="d-flex align-items-center">
          <Row className="align-items-center">
            <Col>
              <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
            </Col>
            {this.state.step.title ?
            <Col>
              <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
            </Col>
            : undefined }
          </Row>
          {this.state.show ? (
            <SweetAlert
              title={this.state.step.title}
              onConfirm={() => this.setState({ show: false })}
              customButtons={
                <React.Fragment>
                  <button className="btn btn-outline-primary" onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} onClick={() => this.setState({ show: false })}>OK</button>
                </React.Fragment>
              }
            >
              <div dangerouslySetInnerHTML={{ __html: this.state.step.content.replace(/(<? *script)/gi, 'illegalscript') }} >
              </div>
            </SweetAlert>
          ) : null}
        </CardBody>
      </Card>
      :
      <Card className="h-100 cursor-pointer" onClick={() => this.setState({ show: true })}>
        <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
        <CardBody>
          <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
          { this.state.step.text ? (
          <CardText dangerouslySetInnerHTML={{ __html: this.state.step.text.replace(/(<? *script)/gi, 'illegalscript') }}>
          </CardText>
          ) : undefined }
          {this.state.show ? (
            <SweetAlert
              title={this.state.step.title}
              onConfirm={() => this.setState({ show: false })}
              customButtons={
                <React.Fragment>
                  <button className="btn btn-outline-primary" onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} onClick={() => this.setState({ show: false })}>OK</button>
                </React.Fragment>
              }
            >
              <div dangerouslySetInnerHTML={{ __html: this.state.step.content.replace(/(<? *script)/gi, 'illegalscript') }} >
              </div>
            </SweetAlert>
          ) : null}
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(Popup);