import { all, fork } from "redux-saga/effects";

//public
import LayoutSaga from "./layout/saga";
import AuthSaga from "./auth/login/saga";
import ClientsSaga from "./clients/saga";
import ImagesSaga from "./images/saga";
import UsersSaga from "./users/saga";
import WizardSaga from "./wizard/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(ClientsSaga),
    fork(ImagesSaga),
    fork(UsersSaga),
    fork(WizardSaga),
  ]);
};
