import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

import logoSm from "../../assets/images/logo-mini.png";
import logo from "../../assets/images/logo.png";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isSearch: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/admin" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="32" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="48" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>

            <div className="d-grid">
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps)(
  (Header)
);
