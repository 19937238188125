import firebase from 'firebase/compat/app';
import "firebase/compat/performance";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { getStorage, ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";

import * as storage from "helpers/storage_helper";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      // Initialize Performance Monitoring and get a reference to the service
      const perf = firebase.performance();

      firebase.auth().onAuthStateChanged(user => {
        if (!storage.local.getItem("authUser")) {
          if (user) {
            this.setLoggeedInUser(user);
          } else {
            storage.local.removeItem("authUser");
          }
        }
      });
    }
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        })
    })
  }

  setLoggeedInUser = user => {
    storage.local.setItem("authUser", JSON.stringify(user));
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!storage.local.getItem("authUser")) return null;
    return JSON.parse(storage.local.getItem("authUser"));
  }

  /**
   * Create user
   */
  createUser = (user) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password)
        .then((user) => {
          resolve(user);
        })
        .catch(error => {
          reject(this._handleError(error));
        })
    })
  }

  /**
   * Add user to collection
   */
  addUserToCollection = (user) => {
    const collection = firebase.firestore().collection("users");

    return new Promise((resolve, reject) => {
      collection
        .add(user)
        .then(
          (docRef) => {
            user.id = docRef.id;
            
            resolve(user);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    });
  }

  /**
   * Get users
   */
  getUsers = () => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('users')
        .orderBy('email', 'asc')
        .get()
        .then(
          querySnapshot => {
            const users = querySnapshot.docs.map(doc => {
              return {
                id: doc.id,
                uid: doc.data().uid,
                email: doc.data().email,
                role: doc.data().role
              }
            });
        
            resolve(users);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    })
  }
  
  /**
   * Get user
   */
  getUser = (uid) => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('users')
        .where('uid', '==', uid)
        .get()
        .then(
          snapshot => {
            try {
              const data = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              if (data.length) {
                resolve(data[0]);
              }
              
              resolve(false);
            } catch (error) {
            }
          },
          error => {
            reject(this._handleError(error));
          }
        )
    })
  }

  /**
   * Delete user
   */
  deleteUser = (user) => {
    const collection = firebase.firestore().collection("users");

    return new Promise((resolve, reject) => {
      collection
        .doc(user.id.toString())
        .delete()
        .then(
          () => {
            resolve(true);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    });
  }

  /**
   * Get clients
   */
  getClients = () => {
    let authUser = JSON.parse(storage.local.getItem("authUser"));

    if (authUser.role == 'client') {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection('clients')
          .orderBy('name', 'asc')
          .where('user', '==', authUser.uid)
          .get()
          .then(
            querySnapshot => {
              const clients = querySnapshot.docs.map(doc => {
                return {
                  id: doc.id,
                  name: doc.data().name
                }
              });
         
              resolve(clients);
            },
            error => {
              reject(this._handleError(error));
            }
          )
      })
    }

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('clients')
        .orderBy('name', 'asc')
        .get()
        .then(
          querySnapshot => {
            const clients = querySnapshot.docs.map(doc => {
              return {
                id: doc.id,
                name: doc.data().name
              }
            });
       
            resolve(clients);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    })
  }

  /**
   * Get client
   */
  getClient = (id) => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('clients')
        .doc(id)
        .get()
        .then(
          snapshot => {
            try {
              const client = snapshot.data();
              client.id = snapshot.id;
              
              resolve(client);
            } catch (error) {
              reject(this._handleError(error));
            }
          },
          error => {
            reject(this._handleError(error));
          }
        )
    })
  }

  /**
   * Add client
   */
  addClient = (client) => {
    const collection = firebase.firestore().collection("clients");

    return new Promise((resolve, reject) => {
      collection
        .add(client)
        .then(
          (docRef) => {
            client.id = docRef.id;
            
            resolve(client);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    });
  }

  /**
   * Update client
   */
  updateClient = (client) => {
    const collection = firebase.firestore().collection("clients");

    return new Promise((resolve, reject) => {
      collection
        .doc(client.id.toString())
        .update({
          name: client.name,
          wizard: client.wizard,
          css: client.css,
          flow: client.flow,
          user: client.user
        })
        .then(
          () => {
            resolve(client);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    });
  }

  /**
   * Delete client
   */
   deleteClient = (client) => {
    const collection = firebase.firestore().collection("clients");

    return new Promise((resolve, reject) => {
      collection
        .doc(client.id.toString())
        .delete()
        .then(
          () => {
            resolve(true);
          },
          error => {
            reject(this._handleError(error));
          }
        )
    });
  }

  uploadImages = async (path, files) => {
    const storage = getStorage();

    for (const key of Object.keys(files)) {
      const storageRef = ref(storage, path + files[key].name);
      
      await uploadBytes(storageRef, files[key]);
    }
  }

  getAllImages = async (id) => {
    const storage = getStorage();
    const storageRef = ref(storage, `clients/${id}`);

    return listAll(storageRef);
  }

  getImage = async (id, image) => {
    const storage = getStorage();
    const storageRef = ref(storage, `clients/${id}/${image}`);

    return getDownloadURL(storageRef)
      .then((url) => {
        return url;
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
    
          // ...
    
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  }

  deleteImage = async (id, image) => {
    const storage = getStorage();
    const storageRef = ref(storage, `clients/${id}/${image}`);
    
    return deleteObject(storageRef).then(() => {
      return true;
    }).catch((error) => {
      return false;
    });
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
}

export { initFirebaseBackend, getFirebaseBackend };
