import { takeEvery, put, call } from "redux-saga/effects";
import axios from "axios";

// Login Redux States
import { IMAGES_GET, IMAGE_DELETE } from "./actionTypes";
import { apiError, getImagesSuccess, deleteImageSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

const getImages = (clientId) => {
  return axios.get(process.env.REACT_APP_API_URL + "/client/" + clientId + "/images").then(res => {
    return res.data.data
  }).catch(err => {
  });
}

const deleteImage = (clientId, image) => {
  return axios.delete(process.env.REACT_APP_API_URL + "/client/" + clientId + "/images/" + image).then(res => {
    return true;
  }).catch(err => {
  });
}

function* getAll({ payload: { clientId, callback }}) {
  try {
    let items = [];
    let files = [];
    let be = yield call(getImages, clientId);
    const fb = yield call(fireBaseBackend.getAllImages, clientId);
    
    be = be.map((image) => {
      return {
        isFirebase: false,
        name: image
      };
    });

    fb.items.forEach((imageRef) => {
      files.push({
        isFirebase: true,
        name: imageRef.name
      });
    });
    
    items = be.concat(files);

    yield put(getImagesSuccess(items));

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* remove({ payload: { clientId, image, callback } }) {
  try {
    if (image.isFirebase) {
      const response = yield call(fireBaseBackend.deleteImage, clientId, image.name);
      yield put(deleteImageSuccess(response));
    } else {
      const response = yield call(deleteImage, clientId, image.name);
      yield put(deleteImageSuccess(response));
    }

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* ImageSaga() {
  yield takeEvery(IMAGES_GET, getAll);
  yield takeEvery(IMAGE_DELETE, remove);
}

export default ImageSaga;
