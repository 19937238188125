import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { CLIENTS_GET, CLIENT_GET, CLIENT_ADD, CLIENT_UPDATE, CLIENT_DELETE } from "./actionTypes";
import { apiError, getClientsSuccess, getClientSuccess, editClientSuccess, 
  deleteClientSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* getAll({ payload }) {
  try {
    const response = yield call(fireBaseBackend.getClients);
    yield put(getClientsSuccess(response));

    payload.callback(true);
  } catch (error) {
    yield put(apiError(error));

    payload.callback(false);
  }
}

function* get({ payload }) {
  try {
    const response = yield call(fireBaseBackend.getClient, payload.id);
    yield put(getClientSuccess(response));

    payload.callback(true);
  } catch (error) {
    yield put(apiError(error));

    payload.callback(false);
  }
}

function* add({ payload: { client, callback } }) {
  try {
    const response = yield call(fireBaseBackend.addClient, client);
    
    callback(true, response);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* update({ payload: { client, callback } }) {
  try {
    const response = yield call(fireBaseBackend.updateClient, client);
    yield put(editClientSuccess(response));

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* remove({ payload: { client, callback } }) {
  try {
    const response = yield call(fireBaseBackend.deleteClient, client);
    yield put(deleteClientSuccess(response));

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* ClientSaga() {
  yield takeEvery(CLIENTS_GET, getAll);
  yield takeEvery(CLIENT_GET, get);
  yield takeEvery(CLIENT_ADD, add);
  yield takeEvery(CLIENT_UPDATE, update);
  yield takeEvery(CLIENT_DELETE, remove);
}

export default ClientSaga;
