import {
  CHANGE_LAYOUT,
  CHANGE_TOPBAR_THEME,
} from "./actionTypes";

export const changeLayout = layout => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changeTopbarTheme = topbarTheme => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});
