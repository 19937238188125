import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class NotFound extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Container className="not-found">
          <Row className="text-center">
            <Col>
              <div className="emoji"></div>
              <h1>404</h1>
              <h3>La página a la que trata de acceder no existe.</h3>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

export default NotFound;
