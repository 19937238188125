export const CLIENTS_GET = "CLIENTS_GET";
export const CLIENTS_GET_SUCCESS = "CLIENTS_GET_SUCCESS";
export const CLIENT_GET = "CLIENT_GET";
export const CLIENT_GET_SUCCESS = "CLIENT_GET_SUCCESS";
export const CLIENT_ADD = "CLIENT_ADD";
export const CLIENT_UPDATE = "CLIENT_UPDATE";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_DELETE = "CLIENT_DELETE";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_API_ERROR = "CLIENT_API_ERROR";
export const CLIENT_SET_LOADED = "CLIENT_SET_LOADED";
