import React from "react";
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Image from "./Common/Image";

class Conditional extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: props.step,
      valid: true,
      loading: false,
      error: false
    };
  }

  getSelectOptions(options) {
    let opts = options.split(',');

   return opts.map((o, idx) => {
      let vl = o.split(':');

      return <option key={idx} value={vl[0]}>{vl[1]}</option>;
    });
  }

  makeRequest() {
    let valid = true;
    let url = this.state.step.requestUrl;
    let params = (url.match(new RegExp("([^?=&]+)(=([^&]*))?", 'g' )) || [])
      .reduce( function( result, each, n, every )
      {
        let[ key, value ] = each.split( '=' );
        result[ key ] = value;
        return( result );
      }, {});
    
    for (const key in params) {
      if (params[key] == '*') {
        url = url.replace(`${key}=*`, `${key}=${this.state[key]}`);
      }
    }

    let headers = {};
    this.state.step.requestHeaders.forEach((h) => {
      if (h.value) {
        headers[h.header] = h.value;
      }
    });

    let payload = {};
    this.state.step.controls.forEach((c) => {
      payload[c.fieldName] = this.state[c.fieldName];
    });

    valid = Object.values(payload).every(
      value => !!value
    );

    this.setState({ valid });

    if (valid) {
      this.setState({ loading: true });
      
      switch (this.state.step.requestMethod) {
        case 'GET':
          axios.get(url, {
            headers: headers
          }).then(res => {
            this.redirect(res);
          }).catch(err => {
            this.setState({ error: true });
          }).finally(() => {
            this.setState({ loading: false });
          });
          break;
        case 'POST':
          axios.post(url, payload, {
            headers: headers
          }).then(res => {
            this.redirect(res);
          }).catch(err => {
            this.setState({ error: true });
          }).finally(() => {
            this.setState({ loading: false });
          });
          break;
        case 'PUT':
          axios.put(url, formData, {
            headers: headers
          }).then(res => {
            this.redirect(res);
          }).catch(err => {
            this.setState({ error: true });
          }).finally(() => {
            this.setState({ loading: false });
          });
        break;
      }
    }
  }

  redirect(res) {
    this.state.step.conditions.every((c) => {
      let r = eval(`res.${c.condition}`);
      
      if (r) {
        sessionStorage.setItem('authenticated', true);
        
        window.top.location = '/' + this.props.match.params.id + '/' + c.nodeId;

        return false;
      }

      return true;
    });
  }

  handleChange(name, e){
    var change = {};
    change[name] = e.target.value;

    this.setState(change);
  }

  render() {
    return (
      this.props.wizard.mode == 'icon' ?
      <Card className="icon-card h-100">
        <CardBody className="d-flex align-items-center">
          <Row className="align-items-center">
            <Col>
              <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
            </Col>
            {this.state.step.title ?
            <Col>
              <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
            </Col>
            : undefined }
          </Row>
        </CardBody>
      </Card>
      :
      <Card className="h-100">
        <Image image={this.state.step.resolvedImageUrl} id={this.props.match.params.id} isCard={true} />
        <CardBody>
          <CardTitle className="h4 mt-0">{this.state.step.title}</CardTitle>
          {!this.state.valid ?
          <div className="alert alert-info">Complete los campos requeridos</div>
          : undefined}
          {this.state.error ?
          <div className="alert alert-danger">Ha ocurrido un error. Intente nuevamente.</div>
          : undefined}
          <CardText dangerouslySetInnerHTML={{ __html: this.state.step.content.replace(/(<? *script)/gi, 'illegalscript') }}>
          </CardText>
          {this.state.step.controls.map((c, idx) => {
            if (c.fieldType == 'input') {
              return (
                <Row className="mt-2" key={idx}>
                  <label>{c.fieldLabel}*</label>
                  <input required name={c.fieldName} id={c.fieldName} onChange={this.handleChange.bind(this, c.fieldName)} type="text"></input>
                </Row>
              )
            } else if (c.fieldType == 'select') {
              return (
                <Row className="mt-2" key={idx}>
                  <label>{c.fieldLabel}*</label>
                  <select required name={c.fieldName} id={c.fieldName} onChange={this.handleChange.bind(this, c.fieldName)}>
                    <option value="">Seleccione</option>
                    {this.getSelectOptions(c.fieldValue)}
                  </select>
                </Row>
              )
            }
          })}
          <Row className="mt-4">
            <button type="button" className="btn btn-info" disabled={this.state.loading} onClick={() => { this.makeRequest() }}>Continuar {this.state.loading ? <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> : undefined }</button>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(Conditional);