import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { WIZARD_GET } from "./actionTypes";
import { apiError, getWizardSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* get({ payload }) {
  try {
    const response = yield call(fireBaseBackend.getClient, payload.id);
    yield put(getWizardSuccess(response));

    payload.callback(true);
  } catch (error) {
    yield put(apiError(error));

    payload.callback(false);
  }
}

function* WizardSaga() {
  yield takeEvery(WIZARD_GET, get);
}

export default WizardSaga;
