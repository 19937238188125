import { USERS_GET, USERS_GET_SUCCESS, USER_GET, USER_GET_SUCCESS, USER_ADD, USER_DELETE, USER_DELETE_SUCCESS, 
  USER_API_ERROR, USER_SET_LOADED } from "./actionTypes";

export const getUsers = payload => {
  return {
    type: USERS_GET,
    payload: payload,
  }
};

export const getUsersSuccess = payload => {
  return {
    type: USERS_GET_SUCCESS,
    payload: payload,
  }
};

export const getUser = payload => {
  return {
    type: USER_GET,
    payload: payload,
  }
};

export const getUserSuccess = payload => {
  return {
    type: USER_GET_SUCCESS,
    payload: payload,
  }
};

export const addUser = (user, callback) => {
  return {
    type: USER_ADD,
    payload: { user, callback },
  };
};

export const deleteUser = (user, callback) => {
  return {
    type: USER_DELETE,
    payload: { user, callback },
  };
};

export const deleteUserSuccess = payload => {
  return {
    type: USER_DELETE_SUCCESS,
    payload: payload,
  };
};

export const apiError = error => {
  return {
    type: USER_API_ERROR,
    payload: error,
  }
};

export const userSetLoaded = error => {
  return {
    type: USER_SET_LOADED,
    payload: error,
  }
};