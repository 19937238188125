import { IMAGES_GET, IMAGES_GET_SUCCESS, IMAGE_DELETE, IMAGE_DELETE_SUCCESS, 
  IMAGE_API_ERROR, IMAGE_SET_LOADED } from "./actionTypes";

export const getImages = payload => {
  return {
    type: IMAGES_GET,
    payload: payload,
  }
};

export const getImagesSuccess = payload => {
  return {
    type: IMAGES_GET_SUCCESS,
    payload: payload,
  }
};

export const deleteImage = (clientId, image, callback) => {
  return {
    type: IMAGE_DELETE,
    payload: { clientId, image, callback },
  };
};

export const deleteImageSuccess = payload => {
  return {
    type: IMAGE_DELETE_SUCCESS,
    payload: payload,
  };
};

export const apiError = error => {
  return {
    type: IMAGE_API_ERROR,
    payload: error,
  }
};

export const imageSetLoaded = error => {
  return {
    type: IMAGE_SET_LOADED,
    payload: error,
  }
};