import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getClients, getClient, addClient, deleteClient } from "../../../store/actions";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalClients: [],
      clients: [],
      columns: [{
        dataField: 'id',
        text: 'ID'
      }, {
        dataField: 'name',
        text: 'Nombre'
      }, {
        dataField: 'actions',
        text: 'Acciones',
        isDummyField: true,
        formatter: (cellContent, row) => {
          return (
            <>
              <Button className="btn-primary" onClick={(e) => { this.props.history.push("/admin/clients/" + row.id); }}>Editar</Button>
              <Button className="btn-images mx-1" onClick={(e) => { this.props.history.push("/admin/clients/" + row.id + "/images"); }}>Imágenes</Button>
              <Button className="btn-info" onClick={(e) => { this.handleDuplicate(row); }}>Duplicar</Button>
              <Button className="btn-danger mx-1" onClick={(e) => { if (window.confirm("Quiere eliminar éste cliente? " + row.name)) { this.handleDelete(row);} }}>Eliminar</Button>
              <Button className="btn-success" onClick={(e) => { window.open("/" + row.id); }}>Ver</Button>
            </>
          );
        }
      }],
      duplicating: false,
      searchText: ''
    };
  }

  componentDidMount() {
    this.props.getClients({
      callback: (result) => this.onClientsSuccess(result, 1)
    });
  }

  onClientsSuccess(success, page) {
    if (success) {
      this.setState({
        originalClients: this.props.clients,
        clients: this.props.clients,
        page,
        duplicating: false
      });
    }
  }

  handleDelete(client) { 
    this.props.deleteClient(
      client,
      () => {
        this.setState({
          clients: []
        }, () => {
          this.props.getClients({
            callback: (result) => this.onClientsSuccess(result)
          });
        });
      }
    );
  }

  handleDuplicate(client) { 
    this.setState({ duplicating: true }, () => this.props.getClient({ id: client.id, callback: (result) => this.onClientSuccess(result) }));
  }

  onClientSuccess(success) {
    if (success) {
      const client = {
        name: 'Copia de ' + this.props.client.name,
        user: this.props.client.user,
        css: this.props.client.css,
        flow: this.props.client.flow || '',
        wizard: JSON.stringify(JSON.parse(this.props.client.wizard), null, 2)
      };

      this.props.addClient(client, (result, response) => {
        this.props.getClients({
          callback: (result) => this.onClientsSuccess(result, 1)
        });
      });
    }
  }

  render() {
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Clientes</CardTitle>
                  <Row>
                    <Col lg="12">
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <PaginationProvider
                              pagination={ paginationFactory({ totalSize: this.state.clients.length, hideSizePerPage: true }) }
                            >
                            {
                              ({
                                paginationProps,
                                paginationTableProps
                              }) => (
                                <ToolkitProvider
                                  keyField='id'
                                  columns={this.state.columns}
                                  data={this.state.clients}
                                  search
                                >
                                  {toolkitProps => (
                                  <div>
                                    <Row className="mb-2 justify-content-between">
                                      <Col>
                                        <div className="search-box d-inline-block">
                                          <div className="position-relative">
                                            <>
                                              <SearchBar
                                                className="search-bar"
                                                placeholder="Buscar..."
                                                  {...toolkitProps.searchProps}
                                                searchText={this.state.searchText}
                                                onSearch={(text) => {
                                                  let filtered = this.state.originalClients.filter(function(c) {
                                                    if (text.trim().length) {
                                                      return c.id.indexOf(text) > -1 || c.name.indexOf(text) > -1;
                                                    }
                                                    
                                                    return true;
                                                  });

                                                  this.setState({ searchText: text, clients: filtered });
                                                }}
                                              />
                                            </>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <BootstrapTable
                                      keyField='id'
                                      columns={this.state.columns}
                                      data={this.state.clients}
                                      responsive
                                      bordered={false}
                                      striped={true}
                                      { ...paginationTableProps }
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      noDataIndication={() => (this.props.loading || this.state.duplicating) ? <div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Cargando...</span></div> : <span>No existen clientes.</span>}
                                    />
                                    <PaginationListStandalone
                                      { ...paginationProps }
                                    />
                                  </div>
                                )
                                }
                                </ToolkitProvider>
                              )
                            }
                          </PaginationProvider>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Clients.propTypes = {
  clients: PropTypes.array,
  client: PropTypes.object,
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { loading, clients, client, error } = state.Clients;
  return {
    loading, clients, client, error
  };
};

export default connect(mapStateToProps, {
  getClients,
  getClient,
  addClient,
  deleteClient
})(withRouter(Clients));