import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUsers, deleteUser } from "../../../store/actions";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  componentDidMount() {
    this.props.getUsers({
      callback: (result) => this.onUsersSuccess(result)
    });
  }

  onUsersSuccess(success) {
    if (success) {
      this.setState({
        users: this.props.users
      });
    }
  }

  handleDelete(user) { 
    this.props.deleteUser(
      user,
      () => {
        this.setState({
          users: []
        }, () => {
          this.props.getUsers({
            callback: (result) => this.onUsersSuccess(result)
          });
        });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Usuarios</CardTitle>
                  <Row>
                    <Col lg="12">
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table className="table table-striped table-bordered">
                            <Thead>
                              <Tr>
                                <Th>ID</Th>
                                <Th>Email</Th>
                                <Th>Rol</Th>
                                <Th>Acciones</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {this.state.users.map((user) =>
                                <Tr className="cursor-pointer" key={user.id}>
                                  <Td>
                                    {user.id}
                                  </Td>
                                  <Td>
                                    {user.email}
                                  </Td>
                                  <Td>
                                    {user.role == 'client' ? 'Cliente' : 'Administrador'}
                                  </Td>
                                  <Td>
                                    <Button className="btn-danger" onClick={(e) => { e.stopPropagation(); if (window.confirm("Quiere eliminar éste usuario? " + user.email)) { this.handleDelete(user);} }}>Eliminar</Button>
                                  </Td>
                                </Tr>
                              )}
                              {!this.props.loading && !this.state.users.length &&
                                <Tr>
                                  <Td colSpan="4">No existen usuarios</Td>
                                </Tr>
                              }
                              {this.props.loading &&
                                <Tr>
                                  <Td colSpan="4">
                                    <div className="spinner-border text-primary m-1" role="status">
                                      <span className="sr-only">Cargando...</span>
                                    </div>
                                  </Td>
                                </Tr>
                              }
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Users.propTypes = {
  users: PropTypes.array,
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { loading, users, error } = state.Users;
  return {
    loading, users, error
  };
};

export default connect(mapStateToProps, {
  getUsers,
  deleteUser
})(withRouter(Users));