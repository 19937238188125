import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutSuccess, apiError } from "./actions";
import * as storage from "helpers/storage_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    )

    const u = yield call(
      fireBaseBackend.getUser,
      response.uid
    );
    
    const authUser = JSON.parse(storage.local.getItem("authUser"));

    if (u) {
      authUser.role = u.role;
    } else {
      authUser.role = 'admin';
    }

    storage.local.removeItem("authUser");
    storage.local.setItem("authUser", JSON.stringify(authUser));

    yield put(loginSuccess(response));

    history.push("/admin");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    storage.local.removeItem("authUser");
    
    const response = yield call(fireBaseBackend.logout)
    yield put(logoutSuccess(response))

    history.push("/admin/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
