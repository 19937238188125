import { CLIENTS_GET, CLIENTS_GET_SUCCESS, CLIENT_GET, CLIENT_GET_SUCCESS, 
  CLIENT_ADD, CLIENT_UPDATE, CLIENT_UPDATE_SUCCESS, CLIENT_DELETE, CLIENT_DELETE_SUCCESS, 
  CLIENT_API_ERROR, CLIENT_SET_LOADED } from "./actionTypes";

export const getClients = payload => {
  return {
    type: CLIENTS_GET,
    payload: payload,
  }
};

export const getClientsSuccess = payload => {
  return {
    type: CLIENTS_GET_SUCCESS,
    payload: payload,
  }
};

export const getClient = payload => {
  return {
    type: CLIENT_GET,
    payload: payload,
  }
};

export const getClientSuccess = payload => {
  return {
    type: CLIENT_GET_SUCCESS,
    payload: payload,
  }
};

export const addClient = (client, callback) => {
  return {
    type: CLIENT_ADD,
    payload: { client, callback },
  };
};

export const editClient = (client, callback) => {
  return {
    type: CLIENT_UPDATE,
    payload: { client, callback },
  };
};

export const editClientSuccess = payload => {
  return {
    type: CLIENT_UPDATE_SUCCESS,
    payload: payload,
  };
};

export const deleteClient = (client, callback) => {
  return {
    type: CLIENT_DELETE,
    payload: { client, callback },
  };
};

export const deleteClientSuccess = payload => {
  return {
    type: CLIENT_DELETE_SUCCESS,
    payload: payload,
  };
};

export const apiError = error => {
  return {
    type: CLIENT_API_ERROR,
    payload: error,
  }
};

export const clientSetLoaded = error => {
  return {
    type: CLIENT_SET_LOADED,
    payload: error,
  }
};