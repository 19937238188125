import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { USERS_GET, USER_GET, USER_ADD, USER_DELETE } from "./actionTypes";
import { apiError, getUsersSuccess, getUserSuccess, deleteUserSuccess } from "./actions";
import { getFirebaseBackend } from "helpers/firebase_helper";

const fireBaseBackend = getFirebaseBackend();

function* getAll({ payload }) {
  try {
    const response = yield call(fireBaseBackend.getUsers);
    yield put(getUsersSuccess(response));

    payload.callback(true);
  } catch (error) {
    yield put(apiError(error));

    payload.callback(false);
  }
}

function* get({ payload }) {
  try {
    const response = yield call(fireBaseBackend.getUser, payload.id);
    yield put(getUserSuccess(response));

    payload.callback(true);
  } catch (error) {
    yield put(apiError(error));

    payload.callback(false);
  }
}

function* add({ payload: { user, callback } }) {
  try {
    const response = yield call(fireBaseBackend.createUser, user);
    
    yield call(fireBaseBackend.addUserToCollection, { uid: response.user.uid, email: user.email, role: user.role });
    
    callback(true, response);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* remove({ payload: { user, callback } }) {
  try {
    const response = yield call(fireBaseBackend.deleteUser, user);
    yield put(deleteUserSuccess(response));

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* UserSaga() {
  yield takeEvery(USERS_GET, getAll);
  yield takeEvery(USER_GET, get);
  yield takeEvery(USER_ADD, add);
  yield takeEvery(USER_DELETE, remove);
}

export default UserSaga;
