import React, { Component } from "react";
import PropTypes, { node } from "prop-types";
import { Col, Modal, ModalBody, Row } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { ChromePicker } from "react-color";
import axios from "axios";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class EditNodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      title: '',
      subtitle: '',
      footer: '',
      hexColor: '#000000',
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1'
      },
      logo: '',
      background: '',
      mode: '',
      imageType: '',
      bgImageType: '',
      clientImages: [],
      editorState: EditorState.createEmpty(),
      editorStatePopup: EditorState.createEmpty(),
      defaultEditorState: null,
      defaultEditorStatePopup: null,
      controls: [{
        fieldType: '',
        fieldName: '',
        fieldLabel: '',
        fieldValue: ''
      }],
      requestUrl: '',
      requestMethod: '',
      requestHeaders: [{
        header: '',
        value: ''
      }],
      requestResponse: '',
      requestClass: '',
      requestLoading: false,
      conditions: [{
        condition: ''
      }]
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node !== this.props.node) {
      if (this.props.node && this.props.node.data && this.props.node.data.content) {
        const content = htmlToDraft(this.props.node.data.content);
        const contentStateContent = ContentState.createFromBlockArray(content.contentBlocks);

        if (this.props.node.data.type == 'html') {
          const text = htmlToDraft(this.props.node.data.text);
          const contentStateText = ContentState.createFromBlockArray(text.contentBlocks);

          this.setState({ defaultEditorState: EditorState.createWithContent(contentStateText), defaultEditorStatePopup: EditorState.createWithContent(contentStateContent), imageType: this.props.node.data.imageType });
        } else {
          this.setState({ defaultEditorState: EditorState.createWithContent(contentStateContent), imageType: this.props.node.data.imageType });
        }

        this.setState({
          controls: this.props.node.data.controls,
          requestMethod: this.props.node.data.requestMethod,
          requestUrl: this.props.node.data.requestUrl,
          requestHeaders: this.props.node.data.requestHeaders,
          conditions: this.props.node.data.conditions
        })
      } else if (this.props.node.id == 1) {
        this.setState({
          title: this.props.node.data.title,
          subtitle: this.props.node.data.subtitle,
          footer: this.props.node.data.footer,
          hexColor: this.props.node.data.color,
          color: this.hexToRgbA(this.props.node.data.color),
          logo: this.props.node.data.logo,
          background: this.props.node.data.background,
          mode: this.props.node.data.mode,
          imageType: this.props.node.data.imageType,
          bgImageType: this.props.node.data.bgImageType
        });
      }
    }

    if (prevProps.clientImages !== this.props.clientImages) {
      this.setState({ clientImages: this.props.clientImages });
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  onEditorStatePopupChange = (editorState) => {
    this.setState({ editorStatePopup: editorState });
  };

  handleValidSubmit(event, values) {
    if (values.type) {
      if (values.type == "html") {
        values.text = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        values.content = draftToHtml(convertToRaw(this.state.editorStatePopup.getCurrentContent()));
      } else {
        values.content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
      }
  
      this.setState({ editorState: EditorState.createEmpty(), editorStatePopup: EditorState.createEmpty(), type: '', imageType: '' });
    }

    if (this.props.node?.id == 1) {
      if (values.imageType == "1") {
        values.logo = values.logoImage;
      } else {
        values.logo = values.logoUrl;
      }

      if (values.bgImageType == "1") {
        values.background = values.backgroundImage;
      } else if (values.bgImageType == "2") {
        values.background = values.backgroundUrl;
      }
      
      delete values.logoUrl;
      delete values.logoImage;
      delete values.backgroundUrl;
      delete values.backgroundImage;
    } else {
      if (values.imageType == "1") {
        values.image = values.imageImage;
      } else {
        values.image = values.imageUrl;
      }

      values.onDelete = this.props.node.data.onDelete;
      values.controls = this.state.controls;
      values.requestHeaders = this.state.requestHeaders;
      values.conditions = this.state.conditions;

      delete values.imageImage;
      delete values.imageUrl;
      delete values.condition;
      delete values.nodeId;
      delete values.header;
      delete values.value;
      delete values.fieldName;
      delete values.fieldLabel;
      delete values.fieldType;
      delete values.fieldValue;
    }

    this.props.onConfirmClick(this.props.node?.id, values);
  }

  handleOnClose() {
    this.setState({ editorState: EditorState.createEmpty(), editorStatePopup: EditorState.createEmpty(), type: '', imageType: '' });
    
    this.props.onCloseClick();
  }

  handleOnDuplicate() {
    this.props.onDuplicateClick(this.props.node);

    this.handleOnClose();
  }

  handleColor(color) {
    this.setState({ color: color.rgb, hexColor: color.hex });
  }

  hexToRgbA(hex) {
    var c;
    
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');

        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }

        c = '0x'+c.join('');

        return {
          r: (c>>16)&255,
          g: (c>>8)&255,
          b: c&255,
          a: '1'
        };
    }

    throw new Error('Bad Hex');
  }

  handleControlChange(idx, e) {
    let controls = [...this.state.controls];
    controls[idx][e.target.name] = e.target.value;
    
    this.setState({ controls });
  }

  handleAddControl() {
    let controls = this.state.controls;
    controls.push({
      fieldType: '',
      fieldName: '',
      fieldLabel: '',
      fieldValue: ''
    });

    this.setState({ controls });
  }

  handleDeleteControl(idx) {
    let controls = [...this.state.controls];
    controls.splice(idx, 1);

    this.setState({ controls });
  }

  handleRequestHeaderChange(idx, e) {
    let requestHeaders = [...this.state.requestHeaders];
    requestHeaders[idx][e.target.name] = e.target.value;
    
    this.setState({ requestHeaders });
  }

  handleAddRequestHeader() {
    let requestHeaders = this.state.requestHeaders;
    requestHeaders.push({
      header: '',
      value: ''
    });

    this.setState({ requestHeaders });
  }

  handleDeleteRequestHeader(idx) {
    let requestHeaders = [...this.state.requestHeaders];
    requestHeaders.splice(idx, 1);

    this.setState({ requestHeaders });
  }

  handleTestRequest() {
    if (this.state.requestMethod && this.state.requestUrl) {
      let url = this.state.requestUrl;
      let params = (url.match(new RegExp("([^?=&]+)(=([^&]*))?", 'g' )) || [])
      .reduce( function( result, each, n, every )
      {
        let[ key, value ] = each.split( '=' );
        result[ key ] = value;
        return( result );
      }, {});
    
      for (const key in params) {
        if (params[key] == '*') {
          url = url.replace(`${key}=*`, `${key}=${this.state[key]}`);
        }
      }

      let headers = {};

      this.state.requestHeaders.forEach((h) => {
        if (h.value) {
          headers[h.header] = h.value;
        }
      });

      let payload = {};
      this.state.controls.forEach((c) => {
        if (c.fieldName) {
          payload[c.fieldName] = c.fieldValue;
        }
      });

      let message = '';

      this.setState({ requestLoading: true, requestResponse: '' });

      switch (this.state.requestMethod) {
        case 'GET':
          axios.get(url, {
            headers: headers
          }).then(res => {
            this.setState({ requestResponse: `${res.status}: ${res.statusText}`, requestClass: 'alert alert-success' });
          }).catch(err => {
            if (err.response) {
              message = `${err.response.status}: ${err.response.data}`
            } else {
              message = err.message
            }

            this.setState({ requestResponse: message, requestClass: 'alert alert-danger' });
          }).finally(() => {
            this.setState({ requestLoading: false });
          });
          break;
        case 'POST':
          axios.post(url, payload, {
            headers: headers
          }).then(res => {
            this.setState({ requestResponse: `${res.status}: ${res.statusText}`, requestClass: 'alert alert-success' });
          }).catch(err => {
            if (err.response) {
              message = `${err.response.status}: ${err.response.data}`;
            } else {
              message = err.message;
            }

            this.setState({ requestResponse: message, requestClass: 'alert alert-danger' });
          }).finally(() => {
            this.setState({ requestLoading: false });
          });
          break;
        case 'PUT':
          axios.put(url, payload, {
            headers: headers
          }).then(res => {
            this.setState({ requestResponse: `${res.status}: ${res.statusText}`, requestClass: 'alert alert-success' });
          }).catch(err => {
            if (err.response) {
              message = `${err.response.status}: ${err.response.data}`;
            } else {
              message = err.message;
            }

            this.setState({ requestResponse: message, requestClass: 'alert alert-danger' });
          }).finally(() => {
            this.setState({ requestLoading: false });
          });
        break;
      }
    }
  }

  handleConditionChange(idx, e) {
    let conditions = [...this.state.conditions];
    conditions[idx][e.target.name] = e.target.value;
    
    this.setState({ conditions });
  }

  handleAddCondition() {
    let conditions = this.state.conditions;
    conditions.push({
      condition: ''
    });

    this.setState({ conditions });
  }

  handleDeleteCondition(idx) {
    let conditions = [...this.state.conditions];
    conditions.splice(idx, 1);

    this.setState({ conditions });
  }

  render() {
    const { editorState, editorStatePopup, defaultEditorState, defaultEditorStatePopup, type } = this.state;

    return (
      <React.Fragment>
        <Modal isOpen={this.props.show} toggle={this.props.onCloseClick} centered={true} size="xl">
          <ModalBody className="py-3 px-5">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <h5>Editar opción</h5>
                </div>
              </Col>
            </Row>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                this.handleValidSubmit(e, v)
              }}
            >
            {this.props.error ? (
              <Alert color="danger">
                {this.props.error}
              </Alert>
            ) : null}
              {
                this.props.node?.id == 1 ?
                <>
                  <Row className="mt-2">
                    <Col>
                      <AvField
                        name="title"
                        value={this.state.title}
                        label="Título"
                        type="text"
                        placeholder="Título"
                        errorMessage=" "
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <AvField
                        name="subtitle"
                        value={this.state.subtitle}
                        label="Subtítulo"
                        type="text"
                        placeholder="Subtítulo"
                        errorMessage=" "
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <AvField
                        name="footer"
                        value={this.state.footer}
                        label="Footer"
                        type="textarea"
                        rows="5"
                        placeholder="Footer"
                        errorMessage=" "
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <AvField
                        name="color"
                        value={this.state.hexColor}
                        label="Color"
                        type="text"
                        placeholder="Color"
                        errorMessage=" "
                        required
                        style={{display: 'none' }}
                      />
                      <div>
                        <div style={{
                          padding: '5px',
                          background: '#fff',
                          borderRadius: '1px',
                          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                          display: 'inline-block',
                          cursor: 'pointer',
                        }} onClick={() => this.setState({ showColorPicker: !this.state.showColorPicker }) }>
                          <div style={{
                            width: '36px',
                            height: '14px',
                            borderRadius: '2px',
                            background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
                          }} />
                        </div>
                        { this.state.showColorPicker ? <div style={{
                            position: 'absolute',
                            zIndex: '2',
                          }}>
                          <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }} onClick={() => this.setState({ showColorPicker: false })}/>
                          <ChromePicker
                            color={this.state.color}
                            onChangeComplete={this.handleColor.bind(this)}
                          />
                        </div> : null }

                      </div>
                    </Col>
                  </Row>

                  { this.props.node?.data.clientId ?
                  <>
                    <Row className="mt-2">
                      <Col>
                        <AvField
                          name="imageType"
                          onChange={(e) => { this.setState({ imageType: e.target.value, logo: '' }) }}
                          value={this.state.imageType}
                          label="Logo"
                          type="select"
                          errorMessage=" "
                        >
                          <option value="">Seleccione</option>
                          <option value="1">Imagen</option>
                          <option value="2">URL/Base64</option>
                        </AvField>
                      </Col>
                    </Row>
                    { this.state.imageType == "1" ?
                    <Row className="mt-2">
                      <Col>
                        <AvField
                          name="logoImage"
                          value={this.state.logo}
                          label=" "
                          type="select"
                          errorMessage=" "
                          required
                        >
                          <option value="">Seleccione</option>
                          {this.state.clientImages.map((i, idx) => {
                            return (
                              <option value={i} key={idx}>{i}</option>
                            )
                          })}
                        </AvField>
                      </Col>
                    </Row>
                    : undefined }
                    { this.state.imageType == "2" ?
                    <Row className="mt-2">
                      <Col>
                        <AvField
                          name="logoUrl"
                          value={this.state.logo}
                          label=" "
                          type="text"
                          placeholder="Logo"
                          errorMessage=" "
                          required
                          helpMessage="Url, ó base64"
                        />
                      </Col>
                    </Row>
                    : undefined }

                    <Row className="mt-2">
                      <Col>
                        <AvField
                          name="bgImageType"
                          onChange={(e) => { this.setState({ bgImageType: e.target.value, background: '' }) }}
                          value={this.state.bgImageType}
                          label="Background"
                          type="select"
                          errorMessage=" "
                        >
                          <option value="">Seleccione</option>
                          <option value="1">Imagen</option>
                          <option value="2">URL/Base64</option>
                        </AvField>
                      </Col>
                    </Row>

                    { this.state.bgImageType == "1" ?
                    <Row className="mt-2">
                      <Col>
                        <AvField
                          name="backgroundImage"
                          value={this.state.background}
                          label=" "
                          type="select"
                          errorMessage=" "
                          required
                        >
                          <option value="">Seleccione</option>
                          {this.state.clientImages.map((i, idx) => {
                            return (
                              <option value={i} key={idx}>{i}</option>
                            )
                          })}
                        </AvField>
                      </Col>
                    </Row>
                    : undefined }
                    { this.state.bgImageType == "2" ?
                    <Row className="mt-2">
                      <Col>
                        <AvField
                          name="backgroundUrl"
                          value={this.state.background}
                          label=" "
                          type="text"
                          placeholder="Background"
                          errorMessage=" "
                          required
                          helpMessage="Url, ó base64"
                        />
                      </Col>
                    </Row>
                    : undefined }
                  </>
                  : undefined }

                  <Row className="mt-2">
                    <Col>
                      <AvField
                        name="mode"
                        value={this.state.mode}
                        label="Modo"
                        type="select"
                        placeholder="Logo"
                        errorMessage=" "
                        required
                      >
                        <option value="">Seleccione</option>
                        <option value="image">Imagen</option>
                        <option value="icon">Icono</option>
                      </AvField>
                    </Col>
                  </Row>
                </>
              :
              <>
              <Row>
                <Col>
                  <AvField
                    name="title"
                    value={this.props.node?.data.title}
                    label="Título"
                    type="text"
                    placeholder="Título"
                    errorMessage=" "
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <AvField
                    name="subtitle"
                    value={this.props.node?.data.subtitle}
                    label="Subtítulo"
                    type="text"
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <AvField
                    name="id"
                    value={this.props.node?.data.id}
                    label="ID"
                    type="text"
                    placeholder="ID"
                    errorMessage=" "
                    helpMessage="En minúsculas, sin caracteres especiales ni tildes. Separado por guiones. Ejemplos: inicio, paso-2, nuevo-turno"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvField
                    name="order"
                    value={this.props.node?.data.order}
                    label="Orden"
                    type="text"
                    placeholder="Orden"
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <AvField
                    name="type"
                    value={this.props.node?.data.type}
                    label="Tipo"
                    type="select"
                    errorMessage=" "
                    required
                    onChange={(event) => {
                      this.setState({ type: event.target.value });
                    }}
                  >
                    <option value="">Seleccione</option>
                    <option value="text">Texto</option>
                    <option value="html">Popup</option>
                    <option value="redirect">Redirección</option>
                    <option value="conditional">Condicional</option>
                    <option value="iframe">Iframe</option>
                  </AvField>
                </Col>
              </Row>
              { type == 'redirect' || this.props.node?.data.type == 'redirect' ?
              <>
                <Row className="mt-2">
                  <Col>
                    <AvField
                      name="url"
                      value={this.props.node?.data.url}
                      label="URL"
                      type="text"
                      placeholder="URL"
                      errorMessage=" "
                      required
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <AvField
                      name="target"
                      value={this.props.node?.data.target}
                      label="Target"
                      type="select"
                      errorMessage=" "
                      required
                    >
                      <option value="">Seleccione</option>
                      <option value="_blank">_blank</option>
                      <option value="_self">_self</option>
                      <option value="_parent">_parent</option>
                      <option value="_top">_top</option>
                    </AvField>
                  </Col>
                </Row>
              </>
              : null }
              <Row className="mt-2">
                <Col>
                  <label>Texto</label>
                  <Editor
                    initialEditorState={editorState}
                    defaultEditorState={defaultEditorState}
                    onEditorStateChange={this.onEditorStateChange.bind(this)}
                    toolbar={{ 
                      options: ['inline', 'blockType', 'fontSize', 'textAlign', 'list', 'link', 'emoji'], 
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                      textAlign: {
                        options: ['left', 'center', 'right', 'justify'],
                      }
                    }}
                  />
                </Col>
              </Row>
              { type == 'html' || this.props.node?.data.type == 'html' ?
              <Row className="mt-2">
                <Col>
                  <label>Contenido del popup</label>
                  <Editor
                    initialEditorState={editorStatePopup}
                    defaultEditorState={defaultEditorStatePopup}
                    onEditorStateChange={this.onEditorStatePopupChange.bind(this)}
                    toolbar={{ 
                      options: ['inline', 'blockType', 'fontSize', 'textAlign', 'list', 'link', 'emoji'], 
                      inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                      textAlign: {
                        options: ['left', 'center', 'right', 'justify'],
                      }
                    }}
                  />
                </Col>
              </Row>
              : null }
              { type == 'conditional' || this.props.node?.data.type == 'conditional' ?
              <>
                <Row className="mt-2">
                  <Col>
                    <label>Valores de entrada</label>
                    {this.state.controls.map((item, idx) => (
                      <Row className="align-items-end mb-2" key={idx}>
                        <Col>
                          <AvField
                            name="fieldType"
                            value={item.fieldType}
                            onChange={e => this.handleControlChange(idx, e)}
                            label="Tipo de control"
                            type="select"
                            errorMessage=" "
                            required
                          >
                            <option value="">Seleccione</option>
                            <option value="input">Input</option>
                            <option value="select">Select</option>
                          </AvField>
                        </Col>
                        <Col>
                          <AvField
                            name="fieldName"
                            value={item.fieldName}
                            onChange={e => this.handleControlChange(idx, e)}
                            label="Nombre"
                            type="text"
                            errorMessage=" "
                            required
                          />
                        </Col>
                        <Col>
                          <AvField
                            name="fieldLabel"
                            value={item.fieldLabel}
                            onChange={e => this.handleControlChange(idx, e)}
                            label="Etiqueta"
                            type="text"
                            errorMessage=" "
                            required
                          />
                        </Col>
                        { item.fieldType == 'select' ?
                        <Col>
                          <AvField
                            name="fieldValue"
                            value={item.fieldValue}
                            onChange={e => this.handleControlChange(idx, e)}
                            label="Valores"
                            type="text"
                            placeholder="value:label,value:label,value:label"
                            errorMessage=" "
                            required
                          />
                        </Col>
                        : undefined }
                        <Col>
                          {idx == this.state.controls.length - 1 ?
                          <button
                            type="button"
                            className="btn btn-info mx-3"
                            onClick={this.handleAddControl.bind(this)}
                          >
                            Agregar
                          </button>
                          : undefined }
                          {this.state.controls.length > 1 ?
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.handleDeleteControl.bind(this, idx)}
                          >
                            Eliminar
                          </button>
                          : undefined }
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <label>Request</label>
                    <Row className="align-items-start mb-2">
                        <Col md={2}>
                          <AvField
                            name="requestMethod"
                            value={this.state.requestMethod}
                            onChange={(e) => { this.setState({ requestMethod: e.target.value }) }}
                            label="Método"
                            type="select"
                            errorMessage=" "
                            required
                          >
                            <option value="">Seleccione</option>
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                          </AvField>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="requestUrl"
                            value={this.state.requestUrl}
                            onChange={(e) => { this.setState({ requestUrl: e.target.value }) }}
                            label="URL"
                            type="text"
                            errorMessage=" "
                            required
                            helpMessage="Si el método es GET, poner cada variable usando el campo Nombre de los valores de entrada y el valor reemplazarlo por *. Ej: https://www.test.com/api/Cliente?Nombre=*&Documento=*"
                          />
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                      {this.state.requestHeaders.map((item, idx) => (
                        <Row className="align-items-end mb-2" key={idx}>
                          <Col>
                            <AvField
                              name="header"
                              value={item.header}
                              onChange={e => this.handleRequestHeaderChange(idx, e)}
                              label="Header"
                              type="select"
                            >
                              <option value="">Seleccione</option>
                              <option value="Accept">Accept</option>
                              <option value="Authorization">Authorization</option>
                              <option value="Content-Type">Content-Type</option>
                            </AvField>
                          </Col>
                          <Col>
                            <AvField
                              name="value"
                              value={item.value}
                              onChange={e => this.handleRequestHeaderChange(idx, e)}
                              label="Valor"
                              type="text"
                            />
                          </Col>
                          <Col>
                            {idx == this.state.requestHeaders.length - 1 ?
                            <button
                              type="button"
                              className="btn btn-info mx-3"
                              onClick={this.handleAddRequestHeader.bind(this)}
                            >
                              Agregar
                            </button>
                            : undefined }
                            {this.state.requestHeaders.length > 1 ?
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={this.handleDeleteRequestHeader.bind(this, idx)}
                            >
                              Eliminar
                            </button>
                            : undefined }
                          </Col>
                        </Row>
                      ))}
                    </Col>
                    </Row>
                    { this.state.requestResponse ?
                    <Row className="mt-2">
                      <Col>
                        <div className={this.state.requestClass} role="alert">{this.state.requestResponse}</div>
                      </Col>
                    </Row>
                     : undefined}
                    <Row className="mt-2"> 
                      <Col>
                        <button
                            type="button"
                            className="btn btn-warning"
                            onClick={this.handleTestRequest.bind(this)}
                            disabled={!this.state.requestMethod || !this.state.requestUrl || this.state.requestLoading}
                          >
                            Test {this.state.requestLoading ? <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> : undefined }
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <label>Condiciones</label>
                    {this.state.conditions.map((item, idx) => (
                      <Row className="align-items-center mb-2" key={idx}>
                        <Col>
                          <AvField
                            name="condition"
                            value={item.condition}
                            onChange={e => this.handleConditionChange(idx, e)}
                            label="Condición"
                            type="text"
                            errorMessage=" "
                            required
                            helpMessage="Ej: data.clients[1].id == 2"
                          />
                        </Col>
                        <Col>
                          <AvField
                            name="nodeId"
                            value={item.nodeId}
                            onChange={e => this.handleConditionChange(idx, e)}
                            label="Nodo ID"
                            type="text"
                            errorMessage=" "
                            required
                            helpMessage="Ej: 1, 2, contacto, sucursales"
                          />
                        </Col>
                        <Col>
                          {idx == this.state.conditions.length - 1 ?
                          <button
                            type="button"
                            className="btn btn-info mx-3"
                            onClick={this.handleAddCondition.bind(this)}
                          >
                            Agregar
                          </button>
                          : undefined }
                          {this.state.conditions.length > 1 ?
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.handleDeleteCondition.bind(this, idx)}
                          >
                            Eliminar
                          </button>
                          : undefined }
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
              : null }
              { type == 'iframe' || this.props.node?.data.type == 'iframe' ?
              <Row className="mt-2">
                <Col>
                  <AvField
                    name="iframeUrl"
                    value={this.props.node?.data.iframeUrl}
                    label="Iframe URL"
                    type="text"
                    placeholder="Iframe URL"
                    errorMessage=" "
                    required
                  />
                </Col>
              </Row>
              : null }
              <Row className="mt-2">
                <Col>
                  <AvField
                    name="secured"
                    value={this.props.node?.data.secured}
                    label="Requiere seguridad"
                    type="checkbox"
                    placeholder="Requiere seguridad"
                  />
                </Col>
              </Row>
              { this.props.node?.data.clientId ?
              <>
                <Row className="mt-2">
                  <Col>
                    <AvField
                      name="imageType"
                      onChange={(e) => { this.setState({ imageType: e.target.value }) }}
                      value={this.state.imageType}
                      label="Imagen"
                      type="select"
                    >
                      <option value="">Seleccione</option>
                      <option value="1">Imagen</option>
                      <option value="2">URL/Base64</option>
                    </AvField>
                  </Col>
                </Row>
                { this.state.imageType == "1" ?
                <Row className="mt-2">
                  <Col>
                    <AvField
                      name="imageImage"
                      value={this.props.node?.data.image}
                      label=" "
                      type="select"
                    >
                      <option value="">Seleccione</option>
                      {this.state.clientImages.map((i, idx) => {
                        return (
                          <option value={i} key={idx}>{i}</option>
                        )
                      })}
                    </AvField>
                  </Col>
                </Row>
                : undefined }
                { this.state.imageType == "2" ?
                <Row className="mt-2">
                  <Col>
                    <AvField
                      name="imageUrl"
                      value={this.props.node?.data.image}
                      label=" "
                      type="text"
                      placeholder="Imagen"
                      helpMessage="Url, ó base64"
                    />
                  </Col>
                </Row>
                : undefined }
              </>
              : undefined }
              </>
              }
              <Row>
                <Col>
                  <div className="text-center mt-3">
                    <button
                      type="submit"
                      className="btn btn-success btn-lg me-2"
                    >
                      Aceptar
                    </button>
                    {this.props.node?.id != 1 ?
                      <button
                        type="button"
                        className="btn btn-info btn-lg me-2"
                        onClick={this.handleOnDuplicate.bind(this)}
                      >
                        Duplicar
                      </button>
                    : undefined }
                    <button
                      type="button"
                      className="btn btn-secondary btn-lg me-2"
                      onClick={this.handleOnClose.bind(this)}
                    >
                      Cancelar
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

EditNodeModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDuplicateClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.bool,
  node: PropTypes.object,
  clientImages: PropTypes.array
};

export default EditNodeModal;
