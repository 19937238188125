import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import * as storage from "helpers/storage_helper";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    if (storage.local.getItem("authUser")) {
      const obj = JSON.parse(storage.local.getItem("authUser"));

      this.setState({ user: obj });
    }

    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isClients: !this.state.isClients })
                      }}
                      to="/admin/clients"
                    >
                      <i className="bx bx-group me-2" />
                      Clientes <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isClients,
                      })}
                    >
                      <Link to="/admin/clients" className="dropdown-item">
                        Listado
                      </Link>
                      { this.state.user?.role != 'client' ?
                      <Link to="/admin/clients/add" className="dropdown-item">
                        Agregar
                      </Link>
                      : undefined }
                    </div>
                  </li>

                  { this.state.user?.role != 'client' ?
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isUsers: !this.state.isUsers })
                      }}
                      to="/admin/users"
                    >
                      <i className="bx bx-user me-2" />
                      Usuarios <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.isUsers,
                      })}
                    >
                      <Link to="/admin/users" className="dropdown-item">
                        Listado
                      </Link>
                      <Link to="/admin/users/add" className="dropdown-item">
                        Agregar
                      </Link>
                    </div>
                  </li>
                  : undefined }
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter((Navbar));
