import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

import ReactFlow, { Controls, ReactFlowProvider, Background } from 'react-flow-renderer';

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import EditNodeModal from "../../../components/Common/EditNodeModal";
import CustomDeleteNode from "../../../components/Common/CustomDeleteNode";

import { addClient, getUsers, apiError } from "../../../store/actions";

import { buildWizard } from "../../../helpers/wizard_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";

let id = 1;

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      user: '',
      flow: '',
      css: '',
      images: '',
      users: [],
      nodes:  [
        {
          id: id.toString(),
          type: 'input',
          data: { label: 'Inicio', title: 'Inicio', color: '#000000' },
          position: { x: 0, y: 0 },
          selectable: false
        },
      ],
      edges: [],
      node: null,
      showEditNodeModal: false,
      showColorPicker: false
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.reactFlowInstance = React.createRef(null);
    this.reactFlowWrapper = React.createRef(null);
    this.connectingNodeId = React.createRef(null);
  }

  componentDidMount() {
    this.props.getUsers({ callback: (result) => this.onUsersSuccess(result) });
  }

  onUsersSuccess(success) {
    if (success) {
      this.setState({
        users: this.props.users
      });
    }
  }

  handleValidSubmit(event, values) {
    values.flow = JSON.stringify(this.reactFlowInstance.toObject());
    values.wizard = buildWizard(this.reactFlowInstance.toObject());
    values.css = this.state.css;
    values.firebaseImages = true;

    this.props.addClient(values, (result, response) => this.onClientAdd(result, response));
  }

  onClientAdd(result, response) {
    if (result) {
      this.addImages(response);
    } else {
      window.scrollTo(0, 0);
    }
  }

  onFileChange(e) {
    this.setState({ images: e.target.files });
  }

  async addImages(response) {
    if (this.state.images.length) {
      const fireBaseBackend = getFirebaseBackend();
      
      await fireBaseBackend.uploadImages(`clients/${response.id}/`, this.state.images);

      this.props.history.push("/admin");
    } else {
      this.props.history.push("/admin");
    }
  }

  handleConfirmEditNode(id, values) {
    let nodes = this.reactFlowInstance.getNodes();
    nodes = nodes.map((n) => {
      if (n.id == id) {
        n.data = {
          ...values,
          label: values.title
        };
      }

      return n;
    });

    this.reactFlowInstance.setNodes(nodes);
    
    this.setState({ showEditNodeModal: false });
  }

  handleCancelEditNode() {
    this.setState({ showEditNodeModal: false });
  }

  handleConnect(connection) {
    let nodes = this.reactFlowInstance.getNodes();
    let edges = this.reactFlowInstance.getEdges();

    nodes = nodes.map((n) => {
      if (n.id == connection.target) {
        n.parent = connection.source;

        edges = edges.map((e) => {
          if (e.id == n.id) {
            e.source = connection.source;
          }

          return e;
        });
      }

      return n;
    });

    this.reactFlowInstance.setNodes(nodes);
    this.reactFlowInstance.setEdges(edges);
  }

  handleDeleteNode(id) {
    let nodes = this.reactFlowInstance.getNodes();
    let edges = this.reactFlowInstance.getEdges();
    nodes = nodes.filter((n) => n.id != id );
    edges = edges.filter((e) => e.id != id );

    this.reactFlowInstance.setNodes(nodes);
    this.reactFlowInstance.setEdges(edges);
  }

  handleDuplicateNode(node) {
    id += 1;

    const newNode = {
      id: id.toString(),
      type: 'customDeleteNode',
      position: this.reactFlowInstance.project({ x: 50, y: 50 }),
      data: { ...node.data, id }
    };

    this.reactFlowInstance.addNodes(newNode);
    this.reactFlowInstance.addEdges({ id: id.toString(), source: null, target: id.toString() });

    this.setState({ showEditNodeModal: false, id });
  }

  render() {
    const nodeTypes = { customDeleteNode: CustomDeleteNode };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Clientes" breadcrumbItem="Agregar" />
            { this.props.loading ? (
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            ) : (
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                this.handleValidSubmit(e, v)
              }}
            >
              {this.props.error ? (
                <Alert color="danger">
                  {this.props.error}
                </Alert>
              ) : null}
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">General</CardTitle>

                      <Row>
                        <Col>
                          <AvField
                            name="name"
                            value={this.state.name}
                            label="Nombre"
                            type="text"
                            placeholder="Nombre"
                            errorMessage=" "
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <AvField
                            name="user"
                            value={this.state.user}
                            label="Usuario"
                            type="select"
                            errorMessage=" "
                          >
                            <option value="">Seleccione</option>
                            {this.state.users.map((u) => {
                              return <option value={u.uid} key={u.uid}>{u.email}</option>
                            })}
                          </AvField>
                        </Col>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card style={{ height: '100vh'}}>
                    <CardBody>
                      <CardTitle className="h4">Workflow</CardTitle>
                      <ReactFlowProvider>
                        <div className="react-flow-wrapper" ref={this.reactFlowWrapper}>
                          <ReactFlow
                            defaultNodes={this.state.nodes}
                            defaultEdges={this.state.edges}
                            defaultEdgeOptions={{ animated: true }}
                            nodeTypes={nodeTypes}
                            fitView
                            fitViewOptions={{ padding: 12 }}
                            zoomOnScroll={false}
                            onInit={(reactFlowInstance) => {
                              this.reactFlowInstance = reactFlowInstance;
                            }}
                            onNodeDoubleClick={(event, node) => {
                              this.setState({ node, showEditNodeModal: true });
                            }}
                            onConnect={(connection) => {
                              this.handleConnect(connection);
                            }}
                            onConnectStart={(_, { nodeId }) => {
                              this.connectingNodeId.current = nodeId;
                            }}
                            onConnectStop={(event) => {
                              const targetIsPane = event.target.classList.contains('react-flow__pane');

                              if (targetIsPane) {
                                id += 1;

                                // we need to remove the wrapper bounds, in order to get the correct position
                                const { top, left } = this.reactFlowWrapper.current.getBoundingClientRect();
                                
                                const newNode = {
                                  id: id.toString(),
                                  type: 'customDeleteNode',
                                  // we are removing the half of the node width (75) to center the new node
                                  position: this.reactFlowInstance.project({ x: event.clientX - left - 75, y: event.clientY - top }),
                                  data: { id: id.toString(), label: 'Opción', title: 'Opción', onDelete: (id) => { this.handleDeleteNode(id); } },
                                };

                                this.reactFlowInstance.addNodes(newNode);
                                this.reactFlowInstance.addEdges({ id: id.toString(), source: this.connectingNodeId.current, target: id.toString() });
                              }
                            }}
                          >
                            <Controls style={{position: 'absolute', top: 10, left: 10}} />
                            <Background variant="lines" gap={20} size={8} />
                          </ReactFlow>
                        </div>
                      </ReactFlowProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">CSS</CardTitle>

                      <AceEditor
                        mode="css"
                        onChange={(css) => { this.setState({ css: css }) }}
                        name="css"
                        width="100%"
                        value={this.state.css}
                        showPrintMargin={false}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          showLineNumbers: true,
                          tabSize: 2,
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Imágenes</CardTitle>
                          <input type="file" name="images" onChange={this.onFileChange.bind(this)} multiple accept="image/*" />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="d-grid justify-content-end mb-3">
                <button type="submit" className="btn btn-primary w-md">
                  Guardar
                </button>
              </div>
            </AvForm>
            )}
          </Container>
          <EditNodeModal 
            show={this.state.showEditNodeModal}
            node={this.state.node}
            onConfirmClick={this.handleConfirmEditNode.bind(this)} 
            onCloseClick={this.handleCancelEditNode.bind(this)}
            onDuplicateClick={this.handleDuplicateNode.bind(this)}></EditNodeModal>
        </div>
      </React.Fragment>
    )
  }
}

Add.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { users } = state.Users;
  const { loading, error } = state.Clients;
  
  return {
    users, loading, error
  };
};

export default connect(mapStateToProps, {
  addClient,
  getUsers,
  apiError
})(withRouter(Add));