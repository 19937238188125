import React, { Component } from "react";
import { Spinner } from "reactstrap";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="loading">
          <Spinner style={{
            height: '6rem',
            width: '6rem'
          }} />
        </div>
      </React.Fragment>
    )
  }
}

export default Loading;
