import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import axios from "axios";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// Import Firebase Configuration file
import { initFirebaseBackend, getFirebaseBackend } from "./helpers/firebase_helper";

import { getWizardSuccess } from "./store/actions";

// layouts
import NonAuthLayout from "./components/NonAuthLayout";
import HorizontalLayout from "./components/HorizontalLayout/";
import Loading from "./components/Common/Loading";
import NotFound from "./components/Common/NotFound";

import Home from "./pages/Home";
import Step from "./pages/Step";

// Import scss
import "./assets/scss/theme.scss";

// Activating Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);
const fireBaseBackend = getFirebaseBackend();

const getWizardRoutes = (array) => {
  var result = [];

  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.options)) {
      result = result.concat(getWizardRoutes(a.options));
    }
  });

  return result;
}

const getLogo = async (clientId, wizard) => {
  var result = [];
  
  if (wizard.logo) {
    if (wizard.logo.indexOf('data:') > -1 || wizard.logo.indexOf('http') > -1) {
      result.push(wizard.logo);
    } else {
      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/client/${clientId}/${wizard.logo}`);
        
        wizard.logo = `${process.env.REACT_APP_API_URL}/client/${clientId}/${wizard.logo}`;
  
        result.push(wizard.logo);
      } catch (error) {
        const fireBaseBackend = getFirebaseBackend();
  
        const url = await fireBaseBackend.getImage(clientId, wizard.logo);
        
        wizard.logo = url;
  
        result.push(url);
      }
    }
  }
  
  return result;
}

const getImages = async (clientId, array) => {
  var result = [];

  await Promise.all(array.map(async (a) => {
    if (a.image) {
      if (a.image.indexOf('data:') > -1 || a.image.indexOf('http') > -1) {
        a.resolvedImageUrl = a.image;

        result.push(a.image);
      } else {
        try {
          await axios.get(`${process.env.REACT_APP_API_URL}/client/${clientId}/${a.image}`);
          
          a.resolvedImageUrl = `${process.env.REACT_APP_API_URL}/client/${clientId}/${a.image}`;

          result.push(`${process.env.REACT_APP_API_URL}/client/${clientId}/${a.image}`);
        } catch (error) {
          const fireBaseBackend = getFirebaseBackend();
          
          const url = await fireBaseBackend.getImage(clientId, a.image);
          
          if (url) {
            a.resolvedImageUrl = url;

            result.push(url);
          }
        }
      }
    }
    
    if (Array.isArray(a.options)) {
      result = result.concat(await getImages(clientId, a.options));
    }
  }));
  
  return result;
}

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      wizardRoutes: [],
      error: false,
      preloadingImages: true
    };
  }

  getBackgroundImage(id, image) {
    if (image) {
      if (image.indexOf('data:') > -1 || image.indexOf('http') > -1) {
        return image;
      }
  
      return `${process.env.REACT_APP_API_URL}/client/${id}/${image}`;
    }
    
    return '';
  }

  cacheImages = async (srcArray) => {
    const promises = srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    });

    await Promise.all(promises);
  };

  async componentDidMount() {
    let id = window.location.pathname.split('/')[1];

    if (id && id != 'admin') {
      try {
        var response = await fireBaseBackend.getClient(id);
        var wizard = JSON.parse(response.wizard);
        let wizardRoutes = getWizardRoutes(wizard.options);
        wizardRoutes.unshift({ path: "/:id", id: "home" });

        // Inner try catch when images fail
        try {
          let preloadLogo = await getLogo(id, wizard);

          if (preloadLogo.length) {
            await this.cacheImages(preloadLogo);
          }
          
          let preloadedImages = await getImages(id, wizard.options);

          if (preloadedImages.length) {
            await this.cacheImages(preloadedImages);
          }
        } catch (error) {
        }

        this.props.getWizardSuccess({ id: id, wizard: JSON.stringify(wizard), css: response.css });

        document.body.style.backgroundImage = `url(${this.getBackgroundImage(id, wizard.background)})`;
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundSize = 'cover';

        this.setState({ wizardRoutes: wizardRoutes, preloadingImages: false });
      } catch (error) {
        // 404
        this.setState({ error: true });
      }
    } else {
      if (id != 'admin') {
        // 404
        this.setState({ error: true });
      }
    }
  }

  render() {
    let isAdmin = window.location.pathname.split('/')[1] == 'admin';
    
    if ((!this.state.wizardRoutes.length && !isAdmin && !this.state.error) || (!isAdmin && this.state.preloadingImages)) {
      return <Loading />
    } else if (this.state.error) {
      return <NotFound />
    }

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={HorizontalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {this.state.wizardRoutes.map((step) => (
              <AppRoute
                path={step.id == "home" ? '/:id' : '/:id/' + step.id}
                layout={NonAuthLayout}
                component={step.id == "home" ? Home : Step}
                key={step.id}
                isAuthProtected={false}
                step={step}
                exact
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  getWizardSuccess
})(withRouter(App));
